/*--------------------------------------------------------------
>> TABLE OF CONTENTS:
----------------------------------------------------------------
1. Normalize
2. Typography
3. Invoice General Style
--------------------------------------------------------------*/
/*--------------------------------------------------------------
2. Normalize
----------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
*[data-v-303b9e74],[data-v-303b9e74]::after,[data-v-303b9e74]::before {
  box-sizing: border-box;
}
html[data-v-303b9e74] {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body[data-v-303b9e74] {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main[data-v-303b9e74] {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1[data-v-303b9e74] {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr[data-v-303b9e74] {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre[data-v-303b9e74] {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a[data-v-303b9e74] {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title][data-v-303b9e74] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b[data-v-303b9e74],
strong[data-v-303b9e74] {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code[data-v-303b9e74],
kbd[data-v-303b9e74],
samp[data-v-303b9e74] {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small[data-v-303b9e74] {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub[data-v-303b9e74],
sup[data-v-303b9e74] {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub[data-v-303b9e74] {
  bottom: -0.25em;
}
sup[data-v-303b9e74] {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img[data-v-303b9e74] {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button[data-v-303b9e74],
input[data-v-303b9e74],
optgroup[data-v-303b9e74],
select[data-v-303b9e74],
textarea[data-v-303b9e74] {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button[data-v-303b9e74],
input[data-v-303b9e74] {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button[data-v-303b9e74],
select[data-v-303b9e74] {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button[data-v-303b9e74],
[type='button'][data-v-303b9e74],
[type='reset'][data-v-303b9e74],
[type='submit'][data-v-303b9e74] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button[data-v-303b9e74]::-moz-focus-inner,
[type='button'][data-v-303b9e74]::-moz-focus-inner,
[type='reset'][data-v-303b9e74]::-moz-focus-inner,
[type='submit'][data-v-303b9e74]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button[data-v-303b9e74]:-moz-focusring,
[type='button'][data-v-303b9e74]:-moz-focusring,
[type='reset'][data-v-303b9e74]:-moz-focusring,
[type='submit'][data-v-303b9e74]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset[data-v-303b9e74] {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend[data-v-303b9e74] {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress[data-v-303b9e74] {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea[data-v-303b9e74] {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type='checkbox'][data-v-303b9e74],
[type='radio'][data-v-303b9e74] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type='number'][data-v-303b9e74]::-webkit-inner-spin-button,
[type='number'][data-v-303b9e74]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type='search'][data-v-303b9e74] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type='search'][data-v-303b9e74]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
[data-v-303b9e74]::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details[data-v-303b9e74] {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary[data-v-303b9e74] {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template[data-v-303b9e74] {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden][data-v-303b9e74] {
  display: none;
}

/*--------------------------------------------------------------
2. Typography
----------------------------------------------------------------*/
body[data-v-303b9e74],
html[data-v-303b9e74] {
  color: #3e3e3e;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6em;
  overflow-x: hidden;
  background-color: #f5f6fa;
}
h1[data-v-303b9e74],
h2[data-v-303b9e74],
h3[data-v-303b9e74],
h4[data-v-303b9e74],
h5[data-v-303b9e74],
h6[data-v-303b9e74] {
  clear: both;
  color: #111;
  padding: 0;
  margin: 0 0 20px 0;
  font-weight: 500;
  line-height: 1.2em;
}
h1[data-v-303b9e74] {
  font-size: 60px;
}
h2[data-v-303b9e74] {
  font-size: 48px;
}
h3[data-v-303b9e74] {
  font-size: 30px;
}
h4[data-v-303b9e74] {
  font-size: 24px;
}
h5[data-v-303b9e74] {
  font-size: 18px;
}
h6[data-v-303b9e74] {
  font-size: 16px;
}
p[data-v-303b9e74],
div[data-v-303b9e74] {
  margin-top: 0;
  line-height: 1.5em;
}
p[data-v-303b9e74] {
  margin-bottom: 15px;
}
ul[data-v-303b9e74] {
  margin: 0 0 25px 0;
  padding-left: 20px;
  list-style: disc;
}
ol[data-v-303b9e74] {
  padding-left: 20px;
  margin-bottom: 25px;
}
ul li[data-v-303b9e74] {
  list-style-type: none;
}
ul li[data-v-303b9e74]:before {
  content: '• ';
  margin-left: -1em;
}
ol li[data-v-303b9e74] {
  list-style-type: none;
}
ol li[data-v-303b9e74]:before {
  content: '- ';
  margin-left: -1em;
}
dfn[data-v-303b9e74],
cite[data-v-303b9e74],
em[data-v-303b9e74],
i[data-v-303b9e74] {
  font-style: italic;
}
blockquote[data-v-303b9e74] {
  margin: 0 15px;
  font-style: italic;
  font-size: 20px;
  line-height: 1.6em;
  margin: 0;
}
address[data-v-303b9e74] {
  margin: 0 0 15px;
}
img[data-v-303b9e74] {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
a[data-v-303b9e74] {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a[data-v-303b9e74]:hover {
  color: #007aff;
}
button[data-v-303b9e74] {
  color: inherit;
  transition: all 0.3s ease;
}
a[data-v-303b9e74]:hover {
  text-decoration: none;
  color: inherit;
}
table[data-v-303b9e74] {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}
th[data-v-303b9e74] {
  text-align: left;
}
td[data-v-303b9e74] {
  border-top: 1px solid #dbdfea;
}
td[data-v-303b9e74] {
  padding: 10px 15px;
  line-height: 1.55em;
}
th[data-v-303b9e74] {
  padding: 10px 15px;
  line-height: 1.55em;
}
dl[data-v-303b9e74] {
  margin-bottom: 25px;
}
dl dt[data-v-303b9e74] {
  font-weight: 600;
}
b[data-v-303b9e74],
strong[data-v-303b9e74] {
  font-weight: bold;
}
pre[data-v-303b9e74] {
  color: #666;
  border: 1px solid #dbdfea;
  font-size: 18px;
  padding: 25px;
  border-radius: 5px;
}
kbd[data-v-303b9e74] {
  font-size: 100%;
  background-color: #666;
  border-radius: 5px;
}
a[data-v-303b9e74]:hover {
  color: #007aff;
}
ul[data-v-303b9e74] {
  padding-left: 15px;
}

/*--------------------------------------------------------------
3. Invoice General Style
----------------------------------------------------------------*/
.tm_f10[data-v-303b9e74] {
  font-size: 10px;
}
.tm_f11[data-v-303b9e74] {
  font-size: 11px;
}
.tm_f12[data-v-303b9e74] {
  font-size: 12px;
}
.tm_f13[data-v-303b9e74] {
  font-size: 13px;
}
.tm_f14[data-v-303b9e74] {
  font-size: 14px;
}
.tm_f15[data-v-303b9e74] {
  font-size: 15px;
}
.tm_f16[data-v-303b9e74] {
  font-size: 16px;
}
.tm_f17[data-v-303b9e74] {
  font-size: 17px;
}
.tm_f18[data-v-303b9e74] {
  font-size: 18px;
}
.tm_f19[data-v-303b9e74] {
  font-size: 19px;
}
.tm_f20[data-v-303b9e74] {
  font-size: 20px;
}
.tm_f21[data-v-303b9e74] {
  font-size: 21px;
}
.tm_f22[data-v-303b9e74] {
  font-size: 22px;
}
.tm_f23[data-v-303b9e74] {
  font-size: 23px;
}
.tm_f24[data-v-303b9e74] {
  font-size: 24px;
}
.tm_f25[data-v-303b9e74] {
  font-size: 25px;
}
.tm_f26[data-v-303b9e74] {
  font-size: 26px;
}
.tm_f27[data-v-303b9e74] {
  font-size: 27px;
}
.tm_f28[data-v-303b9e74] {
  font-size: 28px;
}
.tm_f29[data-v-303b9e74] {
  font-size: 29px;
}
.tm_f30[data-v-303b9e74] {
  font-size: 30px;
}
.tm_f40[data-v-303b9e74] {
  font-size: 40px;
}
.tm_f50[data-v-303b9e74] {
  font-size: 50px;
}
.tm_light[data-v-303b9e74] {
  font-weight: 300;
}
.tm_normal[data-v-303b9e74] {
  font-weight: 400;
}
.tm_medium[data-v-303b9e74] {
  font-weight: 500;
}
.tm_semi_bold[data-v-303b9e74] {
  font-weight: 600;
}
.tm_bold[data-v-303b9e74] {
  font-weight: 700;
}
.tm_m0[data-v-303b9e74] {
  margin: 0px;
}
.tm_mb0[data-v-303b9e74] {
  margin-bottom: 0px;
}
.tm_mb1[data-v-303b9e74] {
  margin-bottom: 1px;
}
.tm_mb2[data-v-303b9e74] {
  margin-bottom: 2px;
}
.tm_mb3[data-v-303b9e74] {
  margin-bottom: 3px;
}
.tm_mb4[data-v-303b9e74] {
  margin-bottom: 4px;
}
.tm_mb5[data-v-303b9e74] {
  margin-bottom: 5px;
}
.tm_mb6[data-v-303b9e74] {
  margin-bottom: 6px;
}
.tm_mb7[data-v-303b9e74] {
  margin-bottom: 7px;
}
.tm_mb8[data-v-303b9e74] {
  margin-bottom: 8px;
}
.tm_mb9[data-v-303b9e74] {
  margin-bottom: 9px;
}
.tm_mb10[data-v-303b9e74] {
  margin-bottom: 10px;
}
.tm_mb11[data-v-303b9e74] {
  margin-bottom: 11px;
}
.tm_mb12[data-v-303b9e74] {
  margin-bottom: 12px;
}
.tm_mb13[data-v-303b9e74] {
  margin-bottom: 13px;
}
.tm_mb14[data-v-303b9e74] {
  margin-bottom: 14px;
}
.tm_mb15[data-v-303b9e74] {
  margin-bottom: 15px;
}
.tm_mb16[data-v-303b9e74] {
  margin-bottom: 16px;
}
.tm_mb17[data-v-303b9e74] {
  margin-bottom: 17px;
}
.tm_mb18[data-v-303b9e74] {
  margin-bottom: 18px;
}
.tm_mb19[data-v-303b9e74] {
  margin-bottom: 19px;
}
.tm_mb20[data-v-303b9e74] {
  margin-bottom: 20px;
}
.tm_mb21[data-v-303b9e74] {
  margin-bottom: 21px;
}
.tm_mb22[data-v-303b9e74] {
  margin-bottom: 22px;
}
.tm_mb23[data-v-303b9e74] {
  margin-bottom: 23px;
}
.tm_mb24[data-v-303b9e74] {
  margin-bottom: 24px;
}
.tm_mb25[data-v-303b9e74] {
  margin-bottom: 25px;
}
.tm_mb26[data-v-303b9e74] {
  margin-bottom: 26px;
}
.tm_mb27[data-v-303b9e74] {
  margin-bottom: 27px;
}
.tm_mb28[data-v-303b9e74] {
  margin-bottom: 28px;
}
.tm_mb29[data-v-303b9e74] {
  margin-bottom: 29px;
}
.tm_mb30[data-v-303b9e74] {
  margin-bottom: 30px;
}
.tm_mb40[data-v-303b9e74] {
  margin-bottom: 40px;
}
.tm_pt25[data-v-303b9e74] {
  padding-top: 25px;
}
.tm_pt0[data-v-303b9e74] {
  padding-top: 0;
}
.tm_radius_6_0_0_6[data-v-303b9e74] {
  border-radius: 6px 0 0 6px;
}
.tm_radius_0_6_6_0[data-v-303b9e74] {
  border-radius: 0 6px 6px 0;
}
.tm_radius_0[data-v-303b9e74] {
  border-radius: 0 !important;
}
.tm_width_1[data-v-303b9e74] {
  width: 8.33333333%;
}
.tm_width_2[data-v-303b9e74] {
  width: 16.66666667%;
}
.tm_width_3[data-v-303b9e74] {
  width: 25%;
}
.tm_width_4[data-v-303b9e74] {
  width: 33.33333333%;
}
.tm_width_5[data-v-303b9e74] {
  width: 41.66666667%;
}
.tm_width_6[data-v-303b9e74] {
  width: 50%;
}
.tm_width_7[data-v-303b9e74] {
  width: 58.33333333%;
}
.tm_width_8[data-v-303b9e74] {
  width: 66.66666667%;
}
.tm_width_9[data-v-303b9e74] {
  width: 75%;
}
.tm_width_10[data-v-303b9e74] {
  width: 83.33333333%;
}
.tm_width_11[data-v-303b9e74] {
  width: 91.66666667%;
}
.tm_width_12[data-v-303b9e74] {
  width: 100%;
}
.tm_border[data-v-303b9e74] {
  border: 1px solid #dbdfea;
}
.tm_border_bottom[data-v-303b9e74] {
  border-bottom: 1px solid #dbdfea;
}
.tm_border_top[data-v-303b9e74] {
  border-top: 1px solid #dbdfea;
}
.tm_border_left[data-v-303b9e74] {
  border-left: 1px solid #dbdfea;
}
.tm_border_right[data-v-303b9e74] {
  border-right: 1px solid #dbdfea;
}
.tm_round_border[data-v-303b9e74] {
  border: 1px solid #dbdfea;
  overflow: hidden;
  border-radius: 6px;
}
.tm_accent_color[data-v-303b9e74],
.tm_accent_color_hover[data-v-303b9e74]:hover {
  color: #007aff;
}
.tm_accent_bg[data-v-303b9e74],
.tm_accent_bg_hover[data-v-303b9e74]:hover {
  background-color: #007aff;
}
.tm_accent_bg_10[data-v-303b9e74] {
  background-color: rgba(0, 122, 255, 0.1);
}
.tm_accent_bg_20[data-v-303b9e74] {
  background-color: rgba(0, 122, 255, 0.15);
}
.tm_green_bg[data-v-303b9e74] {
  background-color: #34c759;
}
.tm_green_bg_15[data-v-303b9e74] {
  background-color: rgba(52, 199, 89, 0.1);
}
.tm_primary_bg[data-v-303b9e74],
.tm_primary_bg_hover[data-v-303b9e74]:hover {
  background-color: #111;
}
.tm_primary_bg_2[data-v-303b9e74] {
  background-color: #000036;
}
.tm_danger_color[data-v-303b9e74] {
  color: red;
}
.tm_primary_color[data-v-303b9e74] {
  color: #111;
}
.tm_secondary_color[data-v-303b9e74] {
  color: #666;
}
.tm_ternary_color[data-v-303b9e74] {
  color: #b5b5b5;
}
.tm_white_color[data-v-303b9e74] {
  color: #fff;
}
.tm_white_color_60[data-v-303b9e74] {
  color: rgba(255, 255, 255, 0.6);
}
.tm_gray_bg[data-v-303b9e74] {
  background: #f5f6fa;
}
.tm_ternary_bg[data-v-303b9e74] {
  background-color: #b5b5b5;
}
.tm_accent_10_bg[data-v-303b9e74] {
  background-color: rgba(0, 122, 255, 0.1);
}
.tm_accent_border[data-v-303b9e74] {
  border-color: #007aff;
}
.tm_accent_border_10[data-v-303b9e74] {
  border-color: rgba(0, 122, 255, 0.1);
}
.tm_accent_border_20[data-v-303b9e74] {
  border-color: rgba(0, 122, 255, 0.2);
}
.tm_accent_border_30[data-v-303b9e74] {
  border-color: rgba(0, 122, 255, 0.3);
}
.tm_accent_border_40[data-v-303b9e74] {
  border-color: rgba(0, 122, 255, 0.4);
}
.tm_accent_border_50[data-v-303b9e74] {
  border-color: rgba(0, 122, 255, 0.5);
}
.tm_primary_border[data-v-303b9e74] {
  border-color: #111;
}
.tm_gray_border[data-v-303b9e74] {
  border-color: #f5f6fa;
}
.tm_primary_border_2[data-v-303b9e74] {
  border-color: #000036;
}
.tm_secondary_border[data-v-303b9e74] {
  border-color: #666;
}
.tm_ternary_border[data-v-303b9e74] {
  border-color: #b5b5b5;
}
.tm_border_color[data-v-303b9e74] {
  border-color: #dbdfea;
}
.tm_border_1[data-v-303b9e74] {
  border-style: solid;
  border-width: 1px;
}
.tm_body_lineheight[data-v-303b9e74] {
  line-height: 1.5em;
}
.tm_invoice_in[data-v-303b9e74] {
  position: relative;
  z-index: 100;
}
.tm_container[data-v-303b9e74] {
  max-width: 880px;
  padding: 0px 15px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.tm_text_center[data-v-303b9e74] {
  text-align: center;
}
.tm_text_uppercase[data-v-303b9e74] {
  text-transform: uppercase;
}
.tm_text_right[data-v-303b9e74] {
  text-align: right;
}
.tm_align_center[data-v-303b9e74] {
  align-items: center;
}
.tm_border_bottom_0[data-v-303b9e74] {
  border-bottom: 0;
}
.tm_border_top_0[data-v-303b9e74] {
  border-top: 0;
}
.tm_table_baseline[data-v-303b9e74] {
  vertical-align: baseline;
}
.tm_border_none[data-v-303b9e74] {
  border: none !important;
}
.tm_flex[data-v-303b9e74] {
  display: flex;
}
.tm_justify_between[data-v-303b9e74] {
  justify-content: space-between;
}
.tm__align_center[data-v-303b9e74] {
  align-items: center;
}
.tm_border_left_none[data-v-303b9e74] {
  border-left-width: 0;
}
.tm_border_right_none[data-v-303b9e74] {
  border-right-width: 0;
}
.tm_table_responsive[data-v-303b9e74] {
  overflow-x: auto;
}
.tm_table_responsive > table[data-v-303b9e74] {
  min-width: 600px;
}
.tm_50_col > *[data-v-303b9e74] {
  width: 50%;
  flex: none;
}
.tm_no_border[data-v-303b9e74] {
  border: none !important;
}
.tm_grid_row[data-v-303b9e74] {
  display: grid;
  grid-gap: 10px 20px;
  list-style: none;
  padding: 0;
}
.tm_col_2[data-v-303b9e74] {
  grid-template-columns: repeat(2, 1fr);
}
.tm_col_3[data-v-303b9e74] {
  grid-template-columns: repeat(3, 1fr);
}
.tm_col_4[data-v-303b9e74] {
  grid-template-columns: repeat(4, 1fr);
}
.tm_max_w_150[data-v-303b9e74] {
  max-width: 150px;
}
.tm_left_auto[data-v-303b9e74] {
  margin-left: auto;
}
hr[data-v-303b9e74] {
  background: #dbdfea;
  height: 1px;
  border: none;
  margin: 0;
}
.tm_invoice[data-v-303b9e74] {
  background: #fff;
  border-radius: 10px;
  padding: 50px;
}
.tm_invoice_footer[data-v-303b9e74] {
  display: flex;
}
.tm_invoice_footer table[data-v-303b9e74] {
  margin-top: -1px;
}
.tm_invoice_footer .tm_left_footer[data-v-303b9e74] {
  width: 58%;
  padding: 10px 15px;
  flex: none;
}
.tm_invoice_footer .tm_right_footer[data-v-303b9e74] {
  width: 42%;
}
.tm_note[data-v-303b9e74] {
  margin-top: 30px;
  font-style: italic;
}
.tm_font_style_normal[data-v-303b9e74] {
  font-style: normal;
}
.tm_sign img[data-v-303b9e74] {
  max-height: 45px;
}
.tm_coffee_shop_img[data-v-303b9e74] {
  position: absolute;
  height: 200px;
  opacity: 0.04;
  top: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.tm_coffee_shop_img img[data-v-303b9e74] {
  max-height: 100%;
}
.tm_invoice.tm_style1 .tm_invoice_right[data-v-303b9e74] {
  flex: none;
  width: 60%;
}
.tm_invoice.tm_po .tm_invoice_right[data-v-303b9e74] {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.tm_invoice.tm_style1 .tm_invoice_table[data-v-303b9e74] {
  grid-gap: 1px;
}
.tm_invoice.tm_style1 .tm_invoice_table > *[data-v-303b9e74] {
  border: 1px solid #dbdfea;
  margin: -1px;
  padding: 8px 15px 10px;
}
.tm_invoice.tm_style1 .tm_invoice_head[data-v-303b9e74] {
  display: flex;
  justify-content: space-between;
}
.tm_invoice.tm_style1 .tm_invoice_head .tm_invoice_right div[data-v-303b9e74] {
  line-height: 1em;
}
.tm_invoice.tm_style1 .tm_invoice_info[data-v-303b9e74] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tm_invoice.tm_style1 .tm_invoice_info_2[data-v-303b9e74] {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dbdfea;
  border-bottom: 1px solid #dbdfea;
  padding: 11px 0;
}
.tm_invoice.tm_style1 .tm_invoice_seperator[data-v-303b9e74] {
  min-height: 18px;
  border-radius: 1.6em;
  flex: 1 1;
  margin-right: 20px;
}
.tm_invoice.tm_style1 .tm_invoice_info_list[data-v-303b9e74] {
  display: flex;
}
.tm_invoice.tm_style1 .tm_invoice_info_list > *[data-v-303b9e74]:not(:last-child) {
  margin-right: 20px;
}
.tm_invoice.tm_style1 .tm_logo img[data-v-303b9e74] {
  max-height: 50px;
}
.tm_invoice.tm_style1 .tm_logo.tm_size1 img[data-v-303b9e74] {
  max-height: 60px;
}
.tm_invoice.tm_style1 .tm_logo.tm_size2 img[data-v-303b9e74] {
  max-height: 70px;
}
.tm_invoice.tm_style1 .tm_grand_total[data-v-303b9e74] {
  padding: 8px 15px;
}
.tm_invoice.tm_style1 .tm_box_3[data-v-303b9e74] {
  display: flex;
}
.tm_invoice.tm_style1 .tm_box_3 > *[data-v-303b9e74] {
  flex: 1 1;
}
.tm_invoice.tm_style1 .tm_box_3 ul[data-v-303b9e74] {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tm_invoice.tm_style1 .tm_box_3 ul li[data-v-303b9e74] {
  display: flex;
}
.tm_invoice.tm_style1 .tm_box_3 ul li[data-v-303b9e74]:not(:last-child) {
  margin-bottom: 5px;
}
.tm_invoice.tm_style1 .tm_box_3 ul span[data-v-303b9e74] {
  flex: none;
}
.tm_invoice.tm_style1 .tm_box_3 ul span[data-v-303b9e74]:first-child {
  margin-right: 5px;
}
.tm_invoice.tm_style1 .tm_box_3 ul span[data-v-303b9e74]:last-child {
  flex: 1 1;
}
.tm_invoice.tm_style2 .tm_invoice_head[data-v-303b9e74] {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdfea;
  padding-bottom: 15px;
  position: relative;
}
.tm_invoice.tm_style2 .tm_invoice_left[data-v-303b9e74] {
  width: 30%;
  flex: none;
}
.tm_invoice.tm_style2 .tm_invoice_right[data-v-303b9e74] {
  width: 70%;
  flex: none;
}
.tm_invoice.tm_style2 .tm_invoice_info[data-v-303b9e74] {
  display: flex;
}
.tm_invoice.tm_style2 .tm_invoice_info_left[data-v-303b9e74] {
  width: 30%;
  flex: none;
}
.tm_invoice.tm_style2 .tm_invoice_info_right[data-v-303b9e74] {
  width: 70%;
  flex: none;
}
.tm_invoice.tm_style2 .tm_logo img[data-v-303b9e74] {
  max-height: 60px;
}
.tm_invoice.tm_style2 .tm_invoice_title[data-v-303b9e74] {
  line-height: 0.8em;
}
.tm_invoice.tm_style2 .tm_invoice_info_in[data-v-303b9e74] {
  padding: 12px 20px;
  border-radius: 10px;
}
.tm_invoice.tm_style2 .tm_card_note[data-v-303b9e74] {
  display: inline-block;
  padding: 6px 15px;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.tm_invoice.tm_style2 .tm_invoice_footer .tm_left_footer[data-v-303b9e74] {
  padding-left: 0;
}
.tm_invoice.tm_style1.tm_type1[data-v-303b9e74] {
  padding: 0px 50px 30px;
  position: relative;
  overflow: hidden;
  border-radius: 0;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_head[data-v-303b9e74] {
  height: 110px;
  position: relative;
}
.tm_invoice.tm_style1.tm_type1 .tm_shape_bg[data-v-303b9e74] {
  position: absolute;
  height: 100%;
  width: 70%;
  -webkit-transform: skewX(35deg);
  transform: skewX(35deg);
  top: 0px;
  right: -100px;
  overflow: hidden;
}
.tm_invoice.tm_style1.tm_type1 .tm_shape_bg img[data-v-303b9e74] {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-transform: skewX(-35deg) translateX(-45px);
  transform: skewX(-35deg) translateX(-45px);
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_right[data-v-303b9e74] {
  position: relative;
  z-index: 2;
}
.tm_invoice.tm_style1.tm_type1 .tm_logo img[data-v-303b9e74] {
  max-height: 70px;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_seperator[data-v-303b9e74] {
  margin-right: 0;
  border-radius: 0;
  -webkit-transform: skewX(35deg);
  transform: skewX(35deg);
  position: absolute;
  height: 100%;
  width: 57.5%;
  right: -60px;
  overflow: hidden;
  border: none;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_seperator img[data-v-303b9e74] {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-transform: skewX(-35deg);
  transform: skewX(-35deg);
  -webkit-transform: skewX(-35deg) translateX(-10px);
  transform: skewX(-35deg) translateX(-10px);
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_info[data-v-303b9e74] {
  position: relative;
  padding: 4px 0;
}
.tm_invoice.tm_style1.tm_type1 .tm_card_note[data-v-303b9e74],
.tm_invoice.tm_style1.tm_type1 .tm_invoice_info_list[data-v-303b9e74] {
  position: relative;
  z-index: 1;
}
@media (min-width: 500px) {
.tm_invoice.tm_style1.tm_type2[data-v-303b9e74] {
    position: relative;
    overflow: hidden;
    border-radius: 0;
}
.tm_invoice.tm_style1.tm_type2 td[data-v-303b9e74] {
    padding-top: 12px;
    padding-bottom: 12px;
}
.tm_invoice.tm_style1.tm_type2 .tm_pt0[data-v-303b9e74] {
    padding-top: 0;
}
.tm_invoice.tm_style1.tm_type2 .tm_bars[data-v-303b9e74] {
    display: flex;
    position: absolute;
    top: 0px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    overflow: hidden;
    padding: 0 15px;
}
.tm_invoice.tm_style1.tm_type2 .tm_bars span[data-v-303b9e74] {
    height: 100px;
    width: 5px;
    display: block;
    margin: -15px 20px 0;
    -webkit-transform: rotate(-40deg);
    transform: rotate(-40deg);
}
.tm_invoice.tm_style1.tm_type2 .tm_bars.tm_type1[data-v-303b9e74] {
    top: initial;
    bottom: 0;
}
.tm_invoice.tm_style1.tm_type2 .tm_bars.tm_type1 span[data-v-303b9e74] {
    margin: 0 20px 0;
    position: relative;
    bottom: -15px;
}
.tm_invoice.tm_style1.tm_type2 .tm_shape[data-v-303b9e74] {
    height: 230px;
    width: 250px;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
}
.tm_invoice.tm_style1.tm_type2 .tm_shape .tm_shape_in[data-v-303b9e74] {
    position: absolute;
    height: 350px;
    width: 350px;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    top: -199px;
    left: 67px;
    overflow: hidden;
}
.tm_invoice.tm_style1.tm_type2 .tm_shape.tm_type1[data-v-303b9e74] {
    top: initial;
    bottom: 0;
    right: initial;
    left: 0;
}
.tm_invoice.tm_style1.tm_type2 .tm_shape.tm_type1 .tm_shape_in[data-v-303b9e74] {
    top: 135px;
    left: -153px;
}
.tm_invoice.tm_style1.tm_type2 .tm_shape_2[data-v-303b9e74] {
    height: 120px;
    width: 120px;
    border: 5px solid currentColor;
    padding: 20px;
    position: absolute;
    bottom: -30px;
    right: 77px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.tm_invoice.tm_style1.tm_type2 .tm_shape_2 .tm_shape_2_in[data-v-303b9e74] {
    height: 100%;
    width: 100%;
    border: 20px solid currentColor;
}
.tm_invoice.tm_style1.tm_type2 .tm_shape_2.tm_type1[data-v-303b9e74] {
    left: -86px;
    right: initial;
    bottom: 272px;
}
.tm_invoice.tm_style1.tm_type2 .tm_shape_2.tm_type1 .tm_shape_2_in[data-v-303b9e74] {
    border-width: 6px;
}
.tm_invoice.tm_style1.tm_type2 .tm_invoice_right[data-v-303b9e74] {
    width: 40%;
}
.tm_invoice.tm_style1.tm_type2 .tm_logo img[data-v-303b9e74] {
    max-height: 65px;
}
.tm_invoice.tm_style1.tm_type2 .tm_invoice_footer[data-v-303b9e74] {
    margin-bottom: 120px;
}
.tm_invoice.tm_style1.tm_type2 .tm_right_footer[data-v-303b9e74] {
    position: relative;
    padding: 6px 0;
}
.tm_invoice.tm_style1.tm_type2 .tm_right_footer table[data-v-303b9e74] {
    position: relative;
    z-index: 2;
}
.tm_invoice.tm_style1.tm_type2 .tm_left_footer[data-v-303b9e74] {
    padding: 30px 15px;
}
.tm_invoice.tm_style1.tm_type2 .tm_shape_3[data-v-303b9e74] {
    position: absolute;
    top: 0;
    left: -50px;
    height: 100%;
    width: calc(100% + 150px);
    -webkit-transform: skewX(35deg);
    transform: skewX(35deg);
}
.tm_invoice.tm_style1.tm_type2 .tm_shape_4[data-v-303b9e74] {
    position: absolute;
    bottom: 200px;
    left: 0;
    height: 200px;
    width: 200px;
}
}
.tm_invoice.tm_style1.tm_type3[data-v-303b9e74] {
  position: relative;
  overflow: hidden;
  border-radius: 0;
}
.tm_invoice.tm_style1.tm_type3 .tm_shape_1[data-v-303b9e74] {
  position: absolute;
  top: -1px;
  left: 0;
}
.tm_invoice.tm_style1.tm_type3 .tm_shape_2[data-v-303b9e74] {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}
.tm_invoice.tm_style1.tm_type3 .tm_logo img[data-v-303b9e74] {
  max-height: 60px;
}
.tm_invoice.tm_style1.tm_type3 .tm_invoice_head.tm_mb20[data-v-303b9e74] {
  margin-bottom: 65px;
}
.tm_invoice.tm_style1.tm_type3 .tm_invoice_info_list[data-v-303b9e74] {
  position: relative;
  padding: 10px 0 10px 40px;
}
.tm_invoice.tm_style1.tm_type3 .tm_invoice_info_list_bg[data-v-303b9e74] {
  position: absolute;
  height: 100%;
  width: calc(100% + 100px);
  top: 0;
  left: 0;
  border-radius: 20px 0 0 0px;
  -webkit-transform: skewX(-35deg);
  transform: skewX(-35deg);
}
.tm_invoice.tm_style2.tm_type1[data-v-303b9e74] {
  padding-top: 0;
  padding-bottom: 0;
  border-width: 40px 0 0;
  border-style: solid;
  position: relative;
  overflow: hidden;
}
.tm_invoice.tm_style2.tm_type1.tm_small_border[data-v-303b9e74] {
  border-width: 7px 0 0;
}
.tm_invoice.tm_style2.tm_type1 .tm_shape_bg[data-v-303b9e74] {
  position: absolute;
  height: 100%;
  width: 42%;
  -webkit-transform: skewX(-35deg);
  transform: skewX(-35deg);
  top: 0px;
  left: -100px;
}
.tm_invoice.tm_style2.tm_type1 .tm_invoice_head[data-v-303b9e74] {
  padding-top: 15px;
  border-bottom: none;
}
.tm_invoice.tm_style2.tm_type1 .tm_logo[data-v-303b9e74] {
  position: relative;
  z-index: 2;
}
.tm_invoice.tm_style2.tm_type1 .tm_bottom_invoice[data-v-303b9e74] {
  position: relative;
  display: flex;
  padding: 15px 50px 20px;
  border-top: 1px solid #dbdfea;
  justify-content: space-between;
  align-items: center;
  margin: 30px -50px 0;
}
.tm_invoice_content[data-v-303b9e74] {
  position: relative;
  z-index: 10;
}
.tm_invoice_wrap[data-v-303b9e74] {
  position: relative;
}
.tm_note_list li[data-v-303b9e74]:not(:last-child) {
  margin-bottom: 5px;
}
.tm_list.tm_style1[data-v-303b9e74] {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tm_list.tm_style1 svg[data-v-303b9e74] {
  width: 16px;
  height: initial;
}
.tm_list.tm_style1 .tm_list_icon[data-v-303b9e74] {
  display: flex;
  position: absolute;
  left: 0;
  top: 3px;
}
.tm_list.tm_style1 li[data-v-303b9e74] {
  padding-left: 25px;
  position: relative;
}
.tm_list.tm_style1 li[data-v-303b9e74]:not(:last-child) {
  margin-bottom: 5px;
}
.tm_list.tm_style1.tm_text_right li[data-v-303b9e74] {
  padding-left: 0;
  padding-right: 25px;
}
.tm_list.tm_style1.tm_text_right .tm_list_icon[data-v-303b9e74] {
  left: initial;
  right: 0;
}
.tm_section_heading[data-v-303b9e74] {
  border-width: 0 0 1px 0;
  border-style: solid;
}
.tm_section_heading > span[data-v-303b9e74] {
  display: inline-block;
  padding: 8px 15px;
  border-radius: 7px 7px 0 0;
}
.tm_section_heading .tm_curve_35[data-v-303b9e74] {
  margin-left: 12px;
  margin-right: 0;
}
.tm_section_heading .tm_curve_35 span[data-v-303b9e74] {
  display: inline-block;
}
.tm_padd_15_20[data-v-303b9e74] {
  padding: 15px 20px;
}
.tm_padd_8_20[data-v-303b9e74] {
  padding: 8px 20px;
}
.tm_padd_20[data-v-303b9e74] {
  padding: 20px;
}
.tm_padd_15[data-v-303b9e74] {
  padding: 15px;
}
.tm_padd_10[data-v-303b9e74] {
  padding: 10px;
}
.tm_padd_5[data-v-303b9e74] {
  padding: 5px;
}
.tm_curve_35[data-v-303b9e74] {
  -webkit-transform: skewX(-35deg);
  transform: skewX(-35deg);
  padding: 12px 20px 12px 30px;
  margin-left: 22px;
  margin-right: 22px;
}
.tm_curve_35 > *[data-v-303b9e74] {
  -webkit-transform: skewX(35deg);
  transform: skewX(35deg);
}
.tm_dark_invoice_body[data-v-303b9e74] {
  background-color: #18191a;
}
.tm_dark_invoice[data-v-303b9e74] {
  background: #252526;
  color: rgba(255, 255, 255, 0.65);
}
.tm_dark_invoice .tm_primary_color[data-v-303b9e74] {
  color: rgba(255, 255, 255, 0.9);
}
.tm_dark_invoice .tm_secondary_color[data-v-303b9e74] {
  color: rgba(255, 255, 255, 0.65);
}
.tm_dark_invoice .tm_ternary_color[data-v-303b9e74] {
  color: rgba(255, 255, 255, 0.4);
}
.tm_dark_invoice .tm_gray_bg[data-v-303b9e74] {
  background: rgba(255, 255, 255, 0.08);
}
.tm_dark_invoice .tm_border_color[data-v-303b9e74],
.tm_dark_invoice .tm_round_border[data-v-303b9e74],
.tm_dark_invoice td[data-v-303b9e74],
.tm_dark_invoice th[data-v-303b9e74],
.tm_dark_invoice .tm_border_top[data-v-303b9e74],
.tm_dark_invoice .tm_border_bottom[data-v-303b9e74] {
  border-color: rgba(255, 255, 255, 0.1);
}
.tm_dark_invoice + .tm_invoice_btns[data-v-303b9e74] {
  background: #252526;
  border-color: #252526;
}
@media (min-width: 1000px) {
.tm_invoice_btns[data-v-303b9e74] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
    margin-left: 20px;
    position: absolute;
    left: 100%;
    top: 0;
    box-shadow: -2px 0 24px -2px rgba(43, 55, 72, 0.05);
    border: 3px solid #fff;
    border-radius: 6px;
    background-color: #fff;
}
.tm_invoice_btn[data-v-303b9e74] {
    display: inline-flex;
    align-items: center;
    border: none;
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    position: relative;
}
.tm_invoice_btn svg[data-v-303b9e74] {
    width: 24px;
}
.tm_invoice_btn .tm_btn_icon[data-v-303b9e74] {
    padding: 0;
    display: flex;
    height: 42px;
    width: 42px;
    align-items: center;
    justify-content: center;
}
.tm_invoice_btn .tm_btn_text[data-v-303b9e74] {
    position: absolute;
    left: 100%;
    background-color: #111;
    color: #fff;
    padding: 3px 12px;
    display: inline-block;
    margin-left: 10px;
    border-radius: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: 500;
    min-height: 28px;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
}
.tm_invoice_btn .tm_btn_text[data-v-303b9e74]:before {
    content: '';
    height: 10px;
    width: 10px;
    position: absolute;
    background-color: #111;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -3px;
    top: 50%;
    margin-top: -6px;
    border-radius: 2px;
}
.tm_invoice_btn:hover .tm_btn_text[data-v-303b9e74] {
    opacity: 1;
    visibility: visible;
}
.tm_invoice_btn[data-v-303b9e74]:not(:last-child) {
    margin-bottom: 3px;
}
.tm_invoice_btn.tm_color1[data-v-303b9e74] {
    background-color: rgba(0, 122, 255, 0.1);
    color: #007aff;
    border-radius: 5px 5px 0 0;
}
.tm_invoice_btn.tm_color1[data-v-303b9e74]:hover {
    background-color: rgba(0, 122, 255, 0.2);
}
.tm_invoice_btn.tm_color2[data-v-303b9e74] {
    background-color: rgba(52, 199, 89, 0.1);
    color: #34c759;
    border-radius: 0 0 5px 5px;
}
.tm_invoice_btn.tm_color2[data-v-303b9e74]:hover {
    background-color: rgba(52, 199, 89, 0.2);
}
}
@media (max-width: 999px) {
.tm_invoice_btns[data-v-303b9e74] {
    display: inline-flex;
    justify-content: center;
    margin-top: 0px;
    margin-top: 20px;
    box-shadow: -2px 0 24px -2px rgba(43, 55, 72, 0.05);
    border: 3px solid #fff;
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.tm_invoice_btn[data-v-303b9e74] {
    display: inline-flex;
    align-items: center;
    border: none;
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    position: relative;
    border-radius: 5px;
    padding: 6px 15px;
}
.tm_invoice_btn svg[data-v-303b9e74] {
    width: 24px;
}
.tm_invoice_btn .tm_btn_icon[data-v-303b9e74] {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
.tm_invoice_btn[data-v-303b9e74]:not(:last-child) {
    margin-right: 3px;
}
.tm_invoice_btn.tm_color1[data-v-303b9e74] {
    background-color: rgba(0, 122, 255, 0.1);
    color: #007aff;
}
.tm_invoice_btn.tm_color1[data-v-303b9e74]:hover {
    background-color: rgba(0, 122, 255, 0.2);
}
.tm_invoice_btn.tm_color2[data-v-303b9e74] {
    background-color: rgba(52, 199, 89, 0.1);
    color: #34c759;
}
.tm_invoice_btn.tm_color2[data-v-303b9e74]:hover {
    background-color: rgba(52, 199, 89, 0.2);
}
}
@media (max-width: 767px) {
.tm_col_4[data-v-303b9e74] {
    grid-template-columns: repeat(1, 1fr);
}
.tm_col_2_md[data-v-303b9e74] {
    grid-template-columns: repeat(2, 1fr);
}
.tm_m0_md[data-v-303b9e74] {
    margin: 0;
}
.tm_mb10_md[data-v-303b9e74] {
    margin-bottom: 10px;
}
.tm_mb15_md[data-v-303b9e74] {
    margin-bottom: 15px;
}
.tm_mb20_md[data-v-303b9e74] {
    margin-bottom: 20px;
}
.tm_mobile_hide[data-v-303b9e74] {
    display: none;
}
.tm_invoice[data-v-303b9e74] {
    padding: 30px 20px;
}
.tm_invoice .tm_right_footer[data-v-303b9e74] {
    width: 100%;
}
.tm_invoice_footer[data-v-303b9e74] {
    flex-direction: column-reverse;
}
.tm_invoice_footer .tm_left_footer[data-v-303b9e74] {
    width: 100%;
    border-top: 1px solid #dbdfea;
    margin-top: -1px;
    padding: 15px 0;
}
.tm_invoice.tm_style2 .tm_card_note[data-v-303b9e74] {
    margin-top: 0;
}
.tm_note.tm_text_center[data-v-303b9e74] {
    text-align: left;
}
.tm_note.tm_text_center p br[data-v-303b9e74] {
    display: none;
}
.tm_invoice_footer.tm_type1[data-v-303b9e74] {
    flex-direction: column;
}
.tm_invoice.tm_style2 .tm_invoice_head[data-v-303b9e74] {
    flex-direction: column;
}
.tm_invoice.tm_style2 .tm_invoice_head > *[data-v-303b9e74] {
    width: 100%;
}
.tm_invoice.tm_style2 .tm_invoice_head .tm_invoice_left[data-v-303b9e74] {
    margin-bottom: 15px;
}
.tm_invoice.tm_style2 .tm_invoice_head .tm_text_right[data-v-303b9e74] {
    text-align: left;
}
.tm_invoice.tm_style2 .tm_invoice_info[data-v-303b9e74] {
    flex-direction: column;
}
.tm_invoice.tm_style2 .tm_invoice_info > *[data-v-303b9e74] {
    width: 100%;
}
.tm_invoice.tm_style1.tm_type1[data-v-303b9e74] {
    padding: 30px 20px;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_head[data-v-303b9e74] {
    height: initial;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_info[data-v-303b9e74] {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
    padding-right: 15px;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_seperator[data-v-303b9e74] {
    width: 100%;
    -webkit-transform: initial;
    transform: initial;
    right: 0;
    top: 0;
}
.tm_invoice.tm_style1.tm_type1 .tm_logo img[data-v-303b9e74] {
    max-height: 60px;
}
.tm_invoice.tm_style2.tm_type1[data-v-303b9e74] {
    border-width: 20px 0 0;
}
.tm_invoice.tm_style2.tm_type1 .tm_shape_bg[data-v-303b9e74] {
    width: 250px;
    height: 80px;
}
.tm_invoice.tm_style2.tm_type1 .tm_invoice_head .tm_text_center[data-v-303b9e74] {
    text-align: left;
}
.tm_invoice.tm_style2.tm_type1 .tm_logo[data-v-303b9e74] {
    top: -8px;
    margin-bottom: 15px;
}
.tm_invoice.tm_style2 .tm_invoice_info_in[data-v-303b9e74] {
    padding: 12px 15px;
}
.tm_border_none_md[data-v-303b9e74] {
    border: none !important;
}
.tm_border_left_none_md[data-v-303b9e74] {
    border-left-width: 0;
}
.tm_border_right_none_md[data-v-303b9e74] {
    border-right-width: 0;
}
.tm_padd_left_15_md[data-v-303b9e74] {
    padding-left: 15px !important;
}
.tm_invoice.tm_style2 .tm_logo img[data-v-303b9e74] {
    max-height: 50px;
}
.tm_curve_35[data-v-303b9e74] {
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg);
    margin-left: 0;
    margin-right: 0;
}
.tm_curve_35 > *[data-v-303b9e74] {
    -webkit-transform: inherit;
    transform: inherit;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_seperator[data-v-303b9e74],
  .tm_invoice.tm_style1.tm_type1 .tm_invoice_seperator img[data-v-303b9e74] {
    -webkit-transform: initial;
    transform: initial;
}
.tm_section_heading .tm_curve_35[data-v-303b9e74] {
    margin-left: 0;
}
.tm_shape_2.tm_type1[data-v-303b9e74] {
    display: none;
}
}
@media (max-width: 500px) {
.tm_border_none_sm[data-v-303b9e74] {
    border: none !important;
}
.tm_flex_column_sm[data-v-303b9e74] {
    flex-direction: column;
}
.tm_align_start_sm[data-v-303b9e74] {
    align-items: flex-start;
}
.tm_m0_sm[data-v-303b9e74] {
    margin-bottom: 0;
}
.tm_invoice.tm_style1 .tm_logo[data-v-303b9e74] {
    margin-bottom: 10px;
}
.tm_invoice.tm_style1 .tm_invoice_head[data-v-303b9e74] {
    flex-direction: column;
}
.tm_invoice.tm_style1 .tm_invoice_head .tm_invoice_left[data-v-303b9e74],
  .tm_invoice.tm_style1 .tm_invoice_head .tm_invoice_right[data-v-303b9e74] {
    width: 100%;
}
.tm_invoice.tm_style1 .tm_invoice_head .tm_invoice_right[data-v-303b9e74] {
    text-align: left;
}
.tm_list.tm_style2 li[data-v-303b9e74] {
    flex-direction: column;
}
.tm_list.tm_style2 li > *[data-v-303b9e74] {
    padding: 5px 20px;
}
.tm_col_2[data-v-303b9e74],
  .tm_col_3[data-v-303b9e74] {
    grid-template-columns: repeat(1, 1fr);
}
.tm_col_2_sm[data-v-303b9e74] {
    grid-template-columns: repeat(2, 1fr);
}
.tm_table.tm_style1.tm_type1[data-v-303b9e74] {
    padding: 0px 20px;
}
.tm_box2_wrap[data-v-303b9e74] {
    grid-template-columns: repeat(1, 1fr);
}
.tm_box.tm_style1.tm_type1[data-v-303b9e74] {
    max-width: 100%;
    width: 100%;
}
.tm_invoice.tm_style1 .tm_invoice_left[data-v-303b9e74] {
    max-width: 100%;
}
.tm_f50[data-v-303b9e74] {
    font-size: 30px;
}
.tm_invoice.tm_style1 .tm_invoice_info[data-v-303b9e74] {
    flex-direction: column-reverse;
}
.tm_invoice.tm_style1 .tm_invoice_seperator[data-v-303b9e74] {
    flex: none;
    width: 100%;
    margin-right: 0;
    min-height: 5px;
}
.tm_invoice.tm_style1 .tm_invoice_info_list[data-v-303b9e74] {
    width: 100%;
    flex-wrap: wrap;
}
.tm_invoice.tm_style1 .tm_invoice_seperator + .tm_invoice_info_list[data-v-303b9e74] {
    margin-bottom: 5px;
}
.tm_f30[data-v-303b9e74] {
    font-size: 22px;
}
.tm_invoice.tm_style1 .tm_box_3[data-v-303b9e74] {
    flex-direction: column;
}
.tm_invoice.tm_style1 .tm_box_3 span br[data-v-303b9e74] {
    display: none;
}
.tm_invoice.tm_style1 .tm_box_3 > *[data-v-303b9e74]:not(:last-child) {
    margin-bottom: 15px;
}
.tm_invoice.tm_style1 .tm_box_3 ul li[data-v-303b9e74] {
    flex-direction: column;
}
.tm_invoice.tm_style1 .tm_box_3 ul li[data-v-303b9e74]:not(:last-child) {
    margin-bottom: 5px;
}
}

/*--------------------------------------------------------------
  Will apply only print window
----------------------------------------------------------------*/
@media print {
.tm_gray_bg[data-v-303b9e74] {
    background-color: #f5f6fa !important;
    -webkit-print-color-adjust: exact;
}
.tm_ternary_bg[data-v-303b9e74] {
    background-color: #b5b5b5 !important;
    -webkit-print-color-adjust: exact;
}
.tm_primary_bg[data-v-303b9e74] {
    background-color: #111 !important;
    -webkit-print-color-adjust: exact;
}
.tm_secondary_bg[data-v-303b9e74] {
    background-color: #666 !important;
    -webkit-print-color-adjust: exact;
}
.tm_accent_bg[data-v-303b9e74] {
    background-color: #007aff;
    -webkit-print-color-adjust: exact;
}
.tm_accent_bg_10[data-v-303b9e74] {
    background-color: rgba(0, 122, 255, 0.1) !important;
    -webkit-print-color-adjust: exact;
}
.tm_accent_bg_20[data-v-303b9e74] {
    background-color: rgba(0, 122, 255, 0.15) !important;
    -webkit-print-color-adjust: exact;
}
.tm_white_color[data-v-303b9e74] {
    color: #fff !important;
    -webkit-print-color-adjust: exact;
}
.tm_accent_color[data-v-303b9e74] {
    color: #007aff !important;
    -webkit-print-color-adjust: exact;
}
.tm_ternary_color[data-v-303b9e74] {
    color: #b5b5b5 !important;
    -webkit-print-color-adjust: exact;
}
.tm_hide_print[data-v-303b9e74] {
    display: none !important;
}
.tm_dark_invoice .tm_gray_bg[data-v-303b9e74] {
    background-color: #111 !important;
    -webkit-print-color-adjust: exact;
}
.tm_dark_invoice[data-v-303b9e74] {
    background: #111 !important;
    color: rgba(255, 255, 255, 0.65) !important;
    -webkit-print-color-adjust: exact;
}
.tm_dark_invoice .tm_gray_bg[data-v-303b9e74] {
    background: rgba(255, 255, 255, 0.05) !important;
    -webkit-print-color-adjust: exact;
}
hr[data-v-303b9e74] {
    background: #dbdfea !important;
    -webkit-print-color-adjust: exact;
}
.tm_col_4[data-v-303b9e74],
  .tm_col_4.tm_col_2_md[data-v-303b9e74] {
    grid-template-columns: repeat(4, 1fr);
}
.tm_col_2_md[data-v-303b9e74] {
    grid-template-columns: repeat(2, 1fr);
}
.tm_mb1[data-v-303b9e74] {
    margin-bottom: 1px;
}
.tm_mb2[data-v-303b9e74] {
    margin-bottom: 2px;
}
.tm_mb3[data-v-303b9e74] {
    margin-bottom: 3px;
}
.tm_mb4[data-v-303b9e74] {
    margin-bottom: 4px;
}
.tm_mb5[data-v-303b9e74] {
    margin-bottom: 5px;
}
.tm_mb6[data-v-303b9e74] {
    margin-bottom: 6px;
}
.tm_mb7[data-v-303b9e74] {
    margin-bottom: 7px;
}
.tm_mb8[data-v-303b9e74] {
    margin-bottom: 8px;
}
.tm_mb9[data-v-303b9e74] {
    margin-bottom: 9px;
}
.tm_mb10[data-v-303b9e74] {
    margin-bottom: 10px;
}
.tm_mb11[data-v-303b9e74] {
    margin-bottom: 11px;
}
.tm_mb12[data-v-303b9e74] {
    margin-bottom: 12px;
}
.tm_mb13[data-v-303b9e74] {
    margin-bottom: 13px;
}
.tm_mb14[data-v-303b9e74] {
    margin-bottom: 14px;
}
.tm_mb15[data-v-303b9e74] {
    margin-bottom: 15px;
}
.tm_mb16[data-v-303b9e74] {
    margin-bottom: 16px;
}
.tm_mb17[data-v-303b9e74] {
    margin-bottom: 17px;
}
.tm_mb18[data-v-303b9e74] {
    margin-bottom: 18px;
}
.tm_mb19[data-v-303b9e74] {
    margin-bottom: 19px;
}
.tm_mb20[data-v-303b9e74] {
    margin-bottom: 20px;
}
.tm_mb21[data-v-303b9e74] {
    margin-bottom: 21px;
}
.tm_mb22[data-v-303b9e74] {
    margin-bottom: 22px;
}
.tm_mb23[data-v-303b9e74] {
    margin-bottom: 23px;
}
.tm_mb24[data-v-303b9e74] {
    margin-bottom: 24px;
}
.tm_mb25[data-v-303b9e74] {
    margin-bottom: 25px;
}
.tm_mb26[data-v-303b9e74] {
    margin-bottom: 26px;
}
.tm_mb27[data-v-303b9e74] {
    margin-bottom: 27px;
}
.tm_mb28[data-v-303b9e74] {
    margin-bottom: 28px;
}
.tm_mb29[data-v-303b9e74] {
    margin-bottom: 29px;
}
.tm_mb30[data-v-303b9e74] {
    margin-bottom: 30px;
}
.tm_mb40[data-v-303b9e74] {
    margin-bottom: 40px;
}
.tm_mobile_hide[data-v-303b9e74] {
    display: block;
}
.tm_invoice[data-v-303b9e74] {
    padding: 10px;
}
.tm_invoice .tm_right_footer[data-v-303b9e74] {
    width: 42%;
}
.tm_invoice_footer[data-v-303b9e74] {
    flex-direction: initial;
}
.tm_invoice_footer .tm_left_footer[data-v-303b9e74] {
    width: 58%;
    padding: 10px 15px;
    flex: none;
    border-top: none;
    margin-top: 0px;
}
.tm_invoice.tm_style2 .tm_card_note[data-v-303b9e74] {
    margin-top: 5px;
}
.tm_note.tm_text_center[data-v-303b9e74] {
    text-align: center;
}
.tm_note.tm_text_center p br[data-v-303b9e74] {
    display: initial;
}
.tm_invoice_footer.tm_type1[data-v-303b9e74] {
    flex-direction: initial;
}
.tm_invoice.tm_style2 .tm_invoice_head[data-v-303b9e74] {
    flex-direction: initial;
}
.tm_invoice.tm_style2 .tm_invoice_head > .tm_invoice_left[data-v-303b9e74] {
    width: 30%;
}
.tm_invoice.tm_style2 .tm_invoice_head > .tm_invoice_right[data-v-303b9e74] {
    width: 70%;
}
.tm_invoice.tm_style2 .tm_invoice_head .tm_invoice_left[data-v-303b9e74] {
    margin-bottom: initial;
}
.tm_invoice.tm_style2 .tm_invoice_head .tm_text_right[data-v-303b9e74] {
    text-align: right;
}
.tm_invoice.tm_style2 .tm_invoice_info[data-v-303b9e74] {
    flex-direction: initial;
}
.tm_invoice.tm_style2 .tm_invoice_info > .tm_invoice_info_left[data-v-303b9e74] {
    width: 30%;
}
.tm_invoice.tm_style2 .tm_invoice_info > .tm_invoice_info_right[data-v-303b9e74] {
    width: 70%;
}
.tm_invoice.tm_style1.tm_type1[data-v-303b9e74] {
    padding: 0px 20px 30px;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_head[data-v-303b9e74] {
    height: 110px;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_info[data-v-303b9e74] {
    padding: 4px 0;
    flex-direction: initial;
    align-items: center;
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_seperator[data-v-303b9e74] {
    top: initial;
    margin-right: 0;
    border-radius: 0;
    -webkit-transform: skewX(35deg);
    transform: skewX(35deg);
    position: absolute;
    height: 100%;
    width: 57.5%;
    right: -60px;
    overflow: hidden;
    border: none;
}
.tm_invoice.tm_style1.tm_type1 .tm_logo img[data-v-303b9e74] {
    max-height: 70px;
}
.tm_invoice.tm_style2.tm_type1[data-v-303b9e74] {
    border-width: 20px 0 0;
}
.tm_invoice.tm_style2.tm_type1 .tm_shape_bg[data-v-303b9e74] {
    height: 100%;
    width: 42%;
}
.tm_invoice.tm_style2.tm_type1 .tm_invoice_head .tm_text_center[data-v-303b9e74] {
    text-align: center;
}
.tm_invoice.tm_style2.tm_type1 .tm_logo[data-v-303b9e74] {
    top: initial;
    margin-bottom: initial;
}
.tm_invoice.tm_style2 .tm_invoice_info_in[data-v-303b9e74] {
    padding: 12px 20px;
}
.tm_invoice.tm_style2 .tm_logo img[data-v-303b9e74] {
    max-height: 60px;
}
.tm_curve_35[data-v-303b9e74] {
    -webkit-transform: skewX(-35deg);
    transform: skewX(-35deg);
    margin-left: 22px;
    margin-right: 22px;
}
.tm_curve_35 > *[data-v-303b9e74] {
    -webkit-transform: skewX(35deg);
    transform: skewX(35deg);
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_seperator[data-v-303b9e74] {
    -webkit-transform: skewX(35deg);
    transform: skewX(35deg);
}
.tm_invoice.tm_style1.tm_type1 .tm_invoice_seperator img[data-v-303b9e74] {
    -webkit-transform: skewX(-35deg) translateX(-45px);
    transform: skewX(-35deg) translateX(-45px);
}
.tm_section_heading .tm_curve_35[data-v-303b9e74] {
    margin-left: 12px;
}
.tm_round_border[data-v-303b9e74] {
    border-top-width: 2px;
}
.tm_border_left_none_md[data-v-303b9e74] {
    border-left-width: 1px;
}
.tm_border_right_none_md[data-v-303b9e74] {
    border-right-width: 1px;
}
.tm_note[data-v-303b9e74] {
    margin-top: 30px;
}
.tm_pagebreak[data-v-303b9e74] {
    page-break-before: always;
}
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
*[data-v-695c591f],[data-v-695c591f]::after,[data-v-695c591f]::before {
  box-sizing: border-box;
}
.tm_pos_invoice_wrap[data-v-695c591f] {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4em;
  margin: 0;
  font-family: 'Inter', sans-serif;

  max-width: 340px;
  margin: auto;
  margin-top: 0px;
  padding: 30px 20px;
  background-color: #fff;
}
.tm_pos_company_logo[data-v-695c591f] {
  display: flex;
  justify-content: center;
  margin-bottom: 7px;
}
.tm_pos_company_logo img[data-v-695c591f] {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  height: auto;
  max-height: 45px;
}
.tm_pos_invoice_top[data-v-695c591f] {
  text-align: center;
  margin-bottom: 10px;
}
.tm_pos_invoice_heading[data-v-695c591f] {
  display: flex;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0;
}
.tm_pos_invoice_heading[data-v-695c591f]:before {
  content: '';
  position: absolute;
  height: 0;
  width: 100%;
  left: 0;
  top: 46%;
  border-top: 1px dashed #b5b5b5;
}
.tm_pos_invoice_heading span[data-v-695c591f] {
  display: inline-flex;
  padding: 0 5px;
  background-color: #fff;
  z-index: 1;
  font-weight: 500;
}
.tm_list[data-v-695c591f] {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding-top: 7px;
}
.tm_list li[data-v-695c591f] {
  display: flex;
  width: 50%;
  font-size: 13px;
  line-height: 1.2em;
  margin-bottom: 7px;
}
.text-right[data-v-695c591f] {
  text-align: right;
  justify-content: flex-end;
}
.tm_list_title[data-v-695c591f] {
  margin-right: 4px;
}
.tm_invoice_seperator[data-v-695c591f] {
  width: 100%;
  border-top: 1px dashed #b5b5b5;
  margin: 1px 0;
  margin-left: auto;
}
.tm_pos_invoice_table[data-v-695c591f] {
  width: 100%;
  line-height: 1.3em;
}
.tm_pos_invoice_table thead th[data-v-695c591f] {
  font-weight: 500;
  color: #111;
  text-align: left;
  padding: 12px 3px 8px;
  border-bottom: 1px dashed #b5b5b5;
}
.tm_pos_invoice_table td[data-v-695c591f] {
  padding: 4px;
}
.tm_pos_invoice_table tbody tr:first-child td[data-v-695c591f] {
  padding-top: 10px;
}
.tm_pos_invoice_table tbody tr:last-child td[data-v-695c591f] {
  padding-bottom: 10px;
}
.tm_pos_invoice_table th[data-v-695c591f]:last-child,
.tm_pos_invoice_table td[data-v-695c591f]:last-child {
  text-align: right;
  padding-right: 0;
}
.tm_pos_invoice_table th[data-v-695c591f]:first-child,
.tm_pos_invoice_table td[data-v-695c591f]:first-child {
  padding-left: 0;
}
.tm_pos_invoice_table tr[data-v-695c591f] {
  vertical-align: baseline;
}
.tm_bill_list[data-v-695c591f] {
  list-style: none;
  margin: 0;
  padding: 5px 0;
}
.tm_bill_list_in[data-v-695c591f] {
  display: flex;
  text-align: right;
  justify-content: space-between;
  padding: 3px 0;
}
.tm_bill_title[data-v-695c591f] {
  padding-right: 20px;
}
.tm_bill_value[data-v-695c591f] {
  width: 90px;
}
.tm_bill_value.tm_bill_focus[data-v-695c591f],
.tm_bill_title.tm_bill_focus[data-v-695c591f] {
  font-weight: 500;
  color: #111;
}
.tm_pos_sample_text[data-v-695c591f] {
  padding: 20px 0 5px;
}
.tm_pos_sample_text img[data-v-695c591f] {
  max-width: 100%;
  vertical-align: middle;
}
.tm_pos_sample_text p[data-v-695c591f] {
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 500;
  color: #111;
  font-size: 16px;
}
.tm_pos_company_name[data-v-695c591f] {
  font-weight: 500;
  color: #111;
  font-size: 20px;
  line-height: 1.2em;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
*[data-v-2a9caf44],[data-v-2a9caf44]::after,[data-v-2a9caf44]::before {
  box-sizing: border-box;
}
.tm_container[data-v-2a9caf44] {
  color: #666;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4em;
  margin: 0;
  font-family: 'Inter', sans-serif;

  max-width: 880px;
  padding: 0px 15px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.tm_pos_invoice_wrap[data-v-2a9caf44] {
  max-width: 340px;
  margin: auto;
  margin-top: 0px;
  padding: 30px 20px;
  background-color: #fff;
}
.tm_pos_company_logo[data-v-2a9caf44] {
  display: flex;
  justify-content: center;
  margin-bottom: 7px;
}
.tm_pos_company_logo img[data-v-2a9caf44] {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  height: auto;
  max-height: 45px;
}
.tm_pos_invoice_top[data-v-2a9caf44] {
  text-align: center;
  margin-bottom: 18px;
}
.tm_pos_invoice_heading[data-v-2a9caf44] {
  display: flex;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0;
}
.tm_pos_invoice_heading[data-v-2a9caf44]:before {
  content: '';
  position: absolute;
  height: 0;
  width: 100%;
  left: 0;
  top: 46%;
  border-top: 1px dashed #666;
}
.tm_pos_invoice_heading span[data-v-2a9caf44] {
  display: inline-flex;
  padding: 0 5px;
  background-color: #fff;
  z-index: 1;
  font-weight: 500;
}
.tm_list.tm_style1[data-v-2a9caf44] {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.tm_list.tm_style1 li[data-v-2a9caf44] {
  display: flex;
  width: 50%;
  font-size: 12px;
  line-height: 1.2em;
  margin-bottom: 7px;
}
.text-right[data-v-2a9caf44] {
  text-align: right;
  justify-content: flex-end;
}
.tm_list_title[data-v-2a9caf44] {
  color: #111;
  margin-right: 4px;
  font-weight: 500;
}
.tm_invoice_seperator[data-v-2a9caf44] {
  width: 150px;
  border-top: 1px dashed #666;
  margin: 9px 0;
  margin-left: auto;
}
.tm_pos_invoice_table[data-v-2a9caf44] {
  width: 100%;
  margin-top: 10px;
  line-height: 1.3em;
}
.tm_pos_invoice_table thead th[data-v-2a9caf44] {
  font-weight: 500;
  color: #111;
  text-align: left;
  padding: 8px 3px;
  border-top: 1px dashed #666;
  border-bottom: 1px dashed #666;
}
.tm_pos_invoice_table td[data-v-2a9caf44] {
  padding: 4px;
}
.tm_pos_invoice_table tbody tr:first-child td[data-v-2a9caf44] {
  padding-top: 10px;
}
.tm_pos_invoice_table tbody tr:last-child td[data-v-2a9caf44] {
  padding-bottom: 10px;
  border-bottom: 1px dashed #666;
}
.tm_pos_invoice_table th[data-v-2a9caf44]:last-child,
.tm_pos_invoice_table td[data-v-2a9caf44]:last-child {
  text-align: right;
  padding-right: 0;
}
.tm_pos_invoice_table th[data-v-2a9caf44]:first-child,
.tm_pos_invoice_table td[data-v-2a9caf44]:first-child {
  padding-left: 0;
}
.tm_pos_invoice_table tr[data-v-2a9caf44] {
  vertical-align: baseline;
}
.tm_bill_list[data-v-2a9caf44] {
  list-style: none;
  margin: 0;
  padding: 12px 0;
  border-bottom: 1px dashed #666;
}
.tm_bill_list_in[data-v-2a9caf44] {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  padding: 3px 0;
}
.tm_bill_title[data-v-2a9caf44] {
  padding-right: 20px;
}
.tm_bill_value[data-v-2a9caf44] {
  width: 90px;
}
.tm_bill_value.tm_bill_focus[data-v-2a9caf44],
.tm_bill_title.tm_bill_focus[data-v-2a9caf44] {
  font-weight: 500;
  color: #111;
}
.tm_pos_invoice_footer[data-v-2a9caf44] {
  text-align: center;
  margin-top: 20px;
}
.tm_pos_sample_text[data-v-2a9caf44] {
  text-align: center;
  padding: 12px 0;
  border-bottom: 1px dashed #666;
  line-height: 1.6em;
  color: #9c9c9c;
}
.tm_pos_company_name[data-v-2a9caf44] {
  font-weight: 500;
  color: #111;
  font-size: 13px;
  line-height: 1.4em;
}
.tm_pos_sample_text ul li[data-v-2a9caf44] {
  list-style-type: none;
}
.tm_pos_sample_text ul li[data-v-2a9caf44]:before {
  content: '• ';
  margin-left: -1em;
}
.tm_pos_sample_text ol li[data-v-2a9caf44] {
  list-style-type: none;
}
.tm_pos_sample_text ol li[data-v-2a9caf44]:before {
  content: '- ';
  margin-left: -1em;
}
